<template>
    <div class="loading-overlay" v-if="loading">
        <div class="loader"></div>
    </div>
    <div v-else class="" :style="{ direction: currentLanguage === 'ar' ? 'rtl' : 'ltr' }">
        <nav class="navbar navbar-expand-lg static">
            <div class="pattern-1T"></div>

            <div class="container py-2">
                <a class="logo mx-3">
                    {{ texts.logo }}
                </a>
                <div class="">
                    <a class="nav-link d-inline-block arabic" @click="toggleLanguage">
                        {{ currentLanguage === 'en' ? 'العربية' : 'English' }}
                    </a>
                </div>
            </div>
        </nav>

        <main class="position-re">
            <!--********************************** -->
            <header class="page-header pt-50 section-padding sub-bg pb-40">
                <div class="container mt-60">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="caption">
                                <h1 class="color-1 fz-40">
                                    {{ texts.headerTitle }}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text mt-30">
                                <p class="text-justify-x" v-html=" texts.headerDescription"></p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">

                        <p>
                            
                            {{ texts.quickAccess0 }}
                        </p>
                        <ul class="rest ul-quick-access">
                            <li class="d-inline-block mb-2">
                                <a href="#correct_method_of_preservation">
                                    {{ texts.quickAccess1 }}
                                </a>
                            </li>
                            <li class="d-inline-block mb-2 mx-1">
                                <a href="#how_to_heat_food">
                                    {{ texts.quickAccess2 }}
                                </a>
                            </li>
                            <li class="d-inline-block mb-2 mx-1">
                                <a href="#allergen_icons">
                                    {{ texts.quickAccess3 }}
                                </a>
                            </li>
                            <li class="d-inline-block mb-2 mx-1">
                                <a href="#how_to_read_a_food_label">
                                    {{ texts.quickAccess4 }}
                                </a>
                            </li>
                            <li class="d-inline-block mb-2">
                                <a href="#ingredients_and_nutritional_values">
                                    {{ texts.quickAccess5 }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            <!--********************************** -->
            <section class="team-crev section-padding pt-2 pb-2 sub-bg">
                <div class="container">
                    <!-- *********** -->
                    <div class="row md-marg">
                        <div class="col-lg-3 col-md-4 col-6" v-for="(category, index) in texts.categories" :key="index">
                            <div class="swiper-slide mb-50">
                                <div class="item">
                                    <div class="img wow fadeInUp" data-wow-delay=".4s">
                                        <img :src="category.img" alt="" />
                                    </div>
                                </div>
                                <div class="info text-center pt-2">
                                    <p class="fz-20">{{ category.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--********************************** -->
            <section class="serv-box section-padding main-bg">
                <div class="container">
                    <div id="correct_method_of_preservation" class="sec-lg-head mb-40">
                        <div class="row">
                            <div class="col-lg-6 col-12">
                                <div class="caption phone-flex md-mb50">
                                    <div class="item-flex d-flex align-items-center">
                                        <div class="icon-img-120">
                                            <img src="assets/images/icons_11.png" alt="" />
                                        </div>
                                        <div class="px-4">
                                            <h2 class="fz-40 fw-700 color-1">
                                                {{ texts.preservationTitle }}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-12">
                                <p class="fz-30">
                                    {{ texts.preservationDescription }}
                                </p>
                            </div>
                        </div>
                        <hr />
                    </div>

                    <div id="how_to_heat_food" class="sec-lg-head mb-40">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="position-re phone-flex">
                                    <div class="item-flex d-flex align-items-center">
                                        <div class="icon-img-80">
                                            <img src="assets/images/icons_12.png" alt="" />
                                        </div>
                                        <div class="px-4">
                                            <h2 class="fz-40 fw-700 color-1 mb-0">
                                                {{ texts.heatingTitle }}
                                            </h2>
                                            <p>
                                                {{ texts.heatingDescription }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- ************** -->
                    <div  class="row d-flex align-items-stretch">
                        <div class="col-lg-4 d-flex" v-for="(step, index) in texts.heatingSteps" :key="index">
                            <div class="serv-item md-mb50 text-center lh-base radius-5">
                                <div class="icon-img-120 mx-auto mb-4">
                                    <img :src="step.img" alt="" />
                                </div>
                                <span class="mb-4 text-left">
                                    {{ step.number }}
                                </span>
                                <p class="mb-15 lh-sm">
                                    {{ step.description }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- ************** -->
                    <div class="row lg-marg mt-80">
                        <div class="col-lg-6 valign">
                            <div class="md-mb50 phone-flex">
                                <div class="item-flex d-flex align-items-center mb-50" v-for="(info, index) in texts.heatingInfoLeft" :key="index">
                                    <div>
                                        <div class="icon-img-60">
                                            <img :src="info.img" alt="" />
                                        </div>
                                    </div>
                                    <div class="cont ms-3">
                                        <p class="mb-0">
                                            {{ info.description }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 valign">
                            <div class="md-mb50 phone-flex">
                                <div class="item-flex d-flex align-items-center mb-50" v-for="(info, index) in texts.heatingInfoRight" :key="index">
                                    <div>
                                        <div class="icon-img-60">
                                            <img :src="info.img" alt="" />
                                        </div>
                                    </div>
                                    <div class="cont ms-3">
                                        <p class="mb-0" :class="{ 'color-2': info.highlight }">
                                            {{ info.description }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- ************** -->
                </div>
            </section>
            <!--********************************** -->
            <section id="how_to_read_a_food_label" class="serv-box section-padding sub-bg">
                <div class="container">
                    <div class="sec-lg-head mb-40 phone-flex">
                        <div class="item-flex d-flex align-items-center">
                            <div class="icon-img-60">
                                <img src="assets/images/icons_13.png" alt="" />
                            </div>
                            <div class="px-4">
                                <h2 class="fz-40 fw-700 color-1 mb-0">
                                    {{ texts.labelReadingTitle }}
                                </h2>
                                <p>
                                    {{ texts.labelReadingDescription }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="">
                        <img src="assets/images/img_1.png" class="d-none" />

                        <div class="img_lab">

                            <button 
                                type="button" 
                                class="btn btn-color-1 btn_lab_1 Etooltip"
                                :data-Etooltip="texts.labelText1"
                            ></button>

                            <button 
                                type="button" 
                                class="btn btn-color-1 btn_lab_2 Etooltip"
                                :data-Etooltip="texts.labelText2"
                            ></button>

                            <button 
                                type="button" 
                                class="btn btn-color-1 btn_lab_3 Etooltip"
                                :data-Etooltip="texts.labelText3"
                            ></button>

                            <button 
                                type="button" 
                                class="btn btn-color-1 btn_lab_4 Etooltip"
                                :data-Etooltip="texts.labelText4"
                            ></button>

                            <button 
                                type="button" 
                                class="btn btn-color-1 btn_lab_5 Etooltip"
                                :data-Etooltip="texts.labelText5"
                            ></button>

                            <button 
                                type="button" 
                                class="btn btn-color-1 btn_lab_6 Etooltip"
                                :data-Etooltip="texts.labelText6"
                            ></button>


                            <div class="img">
                                <img src="assets/images/label/04.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!--********************************** -->
            <section id="allergen_icons" class="serv-box section-padding main-bg">
                <div class="container">
                    <!-- -*-*-*-*-*-*-*-*-*-* -->
                    <div class="sec-lg-head text-center mb-40 wow fadeInUp" data-wow-delay=".4s">
                        <h2 class="fz-40 fw-700 color-1">
                            {{ texts.allergenIconsTitle }}
                        </h2>
                        <p>
                            {{ texts.allergenIconsDescription }}
                        </p>
                    </div>

                    <!-- -*-*-*-*-*-*-*-*-*-* -->
                    <div v-if="isMobile" dir="ltr">
                        <swiper 
                            :modules="modules"
                            :slides-per-view="2"
                            :space-between="30"
                            navigation
                            :pagination="{ clickable: true }"
                            @swiper="onSwiper"
                            @slideChange="onSlideChange"
                            dir="ltr"
                        >
                            <swiper-slide v-for="allergen in texts.allergens" :key="allergen.id">
                                <div class="item-flex d-flex align-items-center mb-50 wow fadeInUp" data-wow-delay=".4s">
                                    <div>
                                        <div class="icon-img-50">
                                            <img :src="allergen.icon" alt="" />
                                        </div>
                                    </div>
                                    <div class="cont ml-10">
                                        <h5 class="lh-sm">{{ allergen.name }}</h5>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div v-else>
                        <div class="feat row mt-80">
                            <div class="col-lg-3 col-md-4 col-6" v-for="allergen in texts.allergens" :key="allergen.id">
                                <div class="item-flex d-flex align-items-center mb-50 wow fadeInUp" data-wow-delay=".4s">
                                    <div>
                                        <div class="icon-img-80">
                                            <img :src="allergen.icon" alt="" />
                                        </div>
                                    </div>
                                    <div class="cont mx-4">
                                        <h5 class="lh-sm">{{ allergen.name }}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -*-*-*-*-*-*-*-*-*-* -->
                </div>
            </section>
            <!--********************************** -->
            <section id="ingredients_and_nutritional_values" class="sub-bg section-padding">
                <div class="container">
                    <div class="sec-lg-head text-center mb-40">
                        <h2 class="fz-40 fw-700 color-1">
                            {{ texts.quickAccess5 }}
                        </h2>
                    </div>
                     <!-- ------------ -->
                     <div class="portfolio">
                        <!-- ------------ -->
                        <div class="row">
                            <div class="filtering col-12 text-center">
                                <div class="filter">
                                    <span 
                                        class="text-center d-inline-block mx-2 fz-20"
                                        :class="{ 'active': '*' === activeFilter }"
                                        @click="setActiveFilter('*')"
                                    > 
                                        {{ currentLanguage === 'ar' ? 'الكل' : 'All' }}
                                    </span>

                                    <span v-for="(filter, key) in menuItems" :key="key" 
                                        class="text-center d-inline-block mx-2 fz-20"
                                        :class="{ 'active': filter.category === activeFilter }"
                                        @click="setActiveFilter(filter.category)"
                                    > 
                                        {{ currentLanguage === 'ar' ? filter.category.title_2 : filter.category.title }}
                                    </span>

                                </div>
                            </div>
                            <div class="col-md-8 text-center mx-auto col-9">
                                <input 

                                        type="text" 
                                        v-model="searchQuery" 
                                        :placeholder="currentLanguage === 'ar' ? 'اسم الصنف' : 'Item name'"
                                        class="mb-y form-control"
                                    />
                            </div>
                        </div>
                        <hr />

                        <div class="gallery mt-40">

                            <div class="row grid md-marg blog-modern">
                                <template v-for="(item ) in filteredItems">
                                    <div 
                                        v-for="(item_2, key_2) in item.items" :key="key_2" 
                                        :class="'col-md-6 col-12 items item mb-50 ' + item.category.id"
                                    >
                                        <div class="item md-mb50">
                                            <div class="cont text-center lh-base mb-2" >
                                                <h5 class="mb-0">
                                                    {{ currentLanguage === 'ar' ? item_2.title_2 : item_2.title }}
                                                </h5>
                                                <div class="lh-base fz-15">
                                                    {{ currentLanguage === 'ar' ? item_2.contents_2 : item_2.contents }}
                                                </div>
                                                <div class="text-center mt-2">

                                                    <span 
                                                        v-for="(allergen, key_3) in item_2.allergens" :key="key_3" 
                                                        class="d-inline-block icon-img-40 Etooltip" 
                                                        :data-Etooltip="currentLanguage === 'ar' ? allergen.title_2 : allergen.title"
                                                    >
                                                        <img :src="getAllergenPoster(allergen.ud)"  />
                                                    </span>


                                                </div>
                                            </div>
                                            <div class="img">
                                                <div 
                                                    v-if="item_2.calories"
                                                    class="text-center"
                                                >
                                                    <h4 class="color-1 fz-50 text-center mb-0 pb-0">
                                                        {{ item_2.calories }}
                                                    </h4>
                                                    <small class="fz-20">
                                                     {{ currentLanguage === 'ar' ? 'كالوري' : 'Calorie' }}
                                                    </small>
                                                </div>
                                                <img
                                                    v-else
                                                    class="lazy p-4"
                                                    :src="getPoster(item_2)"
                                                    :data-src="lazyImage"
                                                    :alt="currentLanguage === 'ar' ? item_2.title_2 : item_2.title"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>


                        </div>
                        
                    </div>
                     <!-- ------------ -->
                    </div>
            </section>
            <!--********************************** -->
        </main>

        <footer class="pt-80 sub-bg">
            <div class="footer-container">
                <div class="sub-footer pt-40 bord-thin-top">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="logo">
                                    <a href="#"> </a>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="copyright d-flex">
                                    <div class="ml-auto">
                                        <p class="fz-13">
                                            © 2025
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    import axios from 'axios';

    import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    import 'swiper/css/scrollbar';

    export default {
        name: "App",
        components: {
            Swiper,
            SwiperSlide,
        },

        setup() {
            const onSwiper = (swiper) => {
                console.log(swiper);
            };
            const onSlideChange = () => {
                console.log("slide change");
            };
            return {
                onSwiper,
                onSlideChange,
                modules: [Navigation, Pagination, Scrollbar, A11y],
            };
        },
        data() {
            return {
                isMobile: false,
                loading: true,
                currentLanguage: "en", // اللغة الافتراضية

                menuItems: [],
                //publicUrl: 'http://portal.kuzama.co',
                publicUrl: 'https://portal.kuzama.net',
                lazyImage: '/assets/images/lazyImage.png',
                //-----------------------
                activeFilter: '*',
                searchQuery: '',
                //-----------------------
                translations: {
                    en: {
                        logo: "Food Products Guide",
                        title: "Welcome to our website",
                        description: "This is a sample description in English.",
                        headerTitle: "Food Products Guide",
                        headerDescription:
                            "Hello, we have created this comprehensive guide to provide you with all the information you need about food products. Whether you are looking for nutritional data, food storage and heating methods, how to read nutritional labels, or information about allergens, you will find it all here. We strive to provide information that helps you make healthy decisions, and to serve and support you in consuming healthy and safe food.",
                        quickAccess0: "Quick Access",
                        quickAccess1: "The Correct Method Of Preservation",
                        quickAccess2: "How To Heat Food",
                        quickAccess3: "Allergen Icons",
                        quickAccess4: "How To Read A Food Label",
                        quickAccess5: "Ingredients & Nutritional Values",
                        categories: [
                            { img: "assets/images/categ_1.jpg", name: "Sandwich" },
                            { img: "assets/images/categ_2.jpg", name: "Dishes" },
                            { img: "assets/images/categ_3.jpg", name: "Salads & Fattah" },
                            { img: "assets/images/categ_4.jpg", name: "Sweets Healthy Options" },
                        ],
                        preservationTitle: "The Correct Method Of Preservation",
                        preservationDescription: "It Is Stored At A Temperature Of 0 - 5 Degrees Celsius",
                        heatingTitle: "How To Heat Food",
                        heatingDescription: "Guide for heating our canned food for a hot and delicious meal",
                        heatingSteps: [
                            { img: "assets/images/icons_1.png", number: "01", description: "Remove the plastic cover from the meal" },
                            { img: "assets/images/icons_2.png", number: "02", description: "Make sure the microwave is running at a temperature of no less than 74 degrees Celsius while heating" },
                            { img: "assets/images/icons_3.png", number: "03", description: "Turn the food while heating to ensure that all parts of the food are heated" },
                        ],
                        heatingInfoLeft: [
                            { img: "assets/images/icons_4.png", description: "Chicken meals from 45 to 90 seconds, as desired" },
                            { img: "assets/images/icons_5.png", description: "Meat meals last from 60 to 90 seconds, as desired" },
                        ],
                        heatingInfoRight: [
                            { img: "assets/images/icons_6.png", description: "The sandwich is heated for 15 to 30 seconds, depending on preference" },
                            { img: "assets/images/icons_7.png", description: "Do not reheat it more than once after following the previous steps", highlight: true },
                        ],
                        labelReadingTitle: "How To Read A Food Label",
                        labelReadingDescription: "Learn how to read a food label and understand important information for your health",
                        labelText1: "Serving size",
                        labelText2: "Calories",
                        labelText3: "When the percentage is 5% or less, it indicates that it is low in this product.",
                        labelText4: "When the percentage is 20% or more, it indicates that it is high in this product.",
                        labelText5: "Total fat\nSaturated fat\nTrans fat\nCholesterol\nSodium\nTotal carbohydrates\nFiber\nSugars\nAdded sugars\nProtein",
                        labelText6: "Vitamin D\nCalcium\nIron\nPotassium",
                        allergenIconsTitle: "Allergen Icons",
                        allergenIconsDescription: "These icons represent common allergens, helping you quickly identify the ingredients to avoid due to allergies.",
                        allergens: [
                            { id: 1, name: "Gluten", icon: "assets/images/allergens/gluten.png" },
                            { id: 2, name: "Peanuts", icon: "assets/images/allergens/peanuts.png" },
                            { id: 3, name: "Celery", icon: "assets/images/allergens/celery.png" },
                            { id: 4, name: "Nuts", icon: "assets/images/allergens/nuts.png" },
                            { id: 5, name: "Mustard", icon: "assets/images/allergens/mustard.png" },
                            { id: 6, name: "Eggs", icon: "assets/images/allergens/eggs.png" },
                            { id: 7, name: "Sesame", icon: "assets/images/allergens/sesame.png" },
                            { id: 8, name: "Milk", icon: "assets/images/allergens/milk.png" },
                            { id: 9, name: "Fish", icon: "assets/images/allergens/fish.png" },
                            { id: 10, name: "Soya", icon: "assets/images/allergens/soya.png" },
                        ],
                    },
                    ar: {
                        logo: "دليل المنتجات الغذائية",
                        title: "مرحبا بكم في موقعنا",
                        description: "هذا وصف توضيحي باللغة العربية.",
                        headerTitle: "دليل المنتجات الغذائية",
                        headerDescription:
                            "مرحبا  لقد قمنا بإنشاء هذا الدليل الشامل لتزويدكم بكافة المعلومات التي تحتاجونها حول المنتجات الغذائية. سواء كنتم تبحثون عن البيانات الغذائية، أو طرق حفظ وتسخين الطعام، أو كيفية قراءة البيانات الغذائية، أو معرفة مسببات الحساسية، فستجدون كل ذلك هنا.  نسعى لتوفير معلومات تساعدكم على اتخاذ قرارات صحية ، ولخدمتكم ودعمكم في تناول طعام صحي وآمن.",
                        quickAccess0: "الوصول السريع",
                        quickAccess1: "طريقة الحفظ الصحيحة",
                        quickAccess2: "طريقة تسخين الطعام",
                        quickAccess3: "أيقونات مسببات الحساسية",
                        quickAccess4: "كيفية قراءة الملصق الغذائي",
                        quickAccess5: "المكونات والقيم الغذائية",
                        categories: [
                            { img: "assets/images/categ_1.jpg", name: "الساندويتش" },
                            { img: "assets/images/categ_2.jpg", name: "الأطباق" },
                            { img: "assets/images/categ_3.jpg", name: "السلطات والفتات" },
                            { img: "assets/images/categ_4.jpg", name: "الحلويات والخيارات الصحية" },
                        ],
                        preservationTitle: "طريقة الحفظ الصحيحة",
                        preservationDescription: "يتم تخزينه عند درجة حرارة 0 - 5 درجات مئوية",
                        heatingTitle: "طريقة تسخين الطعام",
                        heatingDescription: "دليل تسخين الطعام المعلب لوجبة ساخنة وشهية.",
                        heatingSteps: [
                            { img: "assets/images/icons_1.png", number: "01", description: "انزع الغطاء البلاستيكي من الوجبة" },
                            { img: "assets/images/icons_2.png", number: "02", description: "تأكد من عمل الميكرويف على درجة الحرارة 75 مئوية أثناء التسخين" },
                            { img: "assets/images/icons_3.png", number: "03", description: "وجبات الدجاج من 45 إلى 90 ثانية حسب الرغبة" },
                        ],
                        heatingInfoLeft: [
                            { img: "assets/images/icons_4.png", description: "وجبات الدجاج من 45 إلى 90 ثانية حسب الرغبة" },
                            { img: "assets/images/icons_5.png", description: "وجبات اللحم من 60 إلى 90 ثانية حسب الرغبة" },
                        ],
                        heatingInfoRight: [
                            { img: "assets/images/icons_6.png", description: "الساندوتش يتم تسخينها لمدة من 15 إلى 30 ثانية حسب الرغبة" },
                            { img: "assets/images/icons_7.png", description: "لا تعيد تسخينها اكثر من مرة بعد اتباع الخطوات السابقة", highlight: true },
                        ],
                        labelReadingTitle: "كيفية قراءة الملصق الغذائي",
                        labelReadingDescription: "تعرّف على كيفية قراءة الملصق الغذائي وفهم المعلومات المهمة لصحتك.",
                        labelText1: "حجم الحصة الواحدة",
                        labelText2: "السعرات الحرارية",
                        labelText3: "عندما تكون النسبة 5% وأقل تدل على أنها منخفضة في هذا المنتج",
                        labelText4: "عندما تكون النسبة 20% وأكثر تدل على أنها مرتفعة في هذا المنتج",
                        labelText5: "إجمالي الدهون\nالدهون المشبعة\nالدهون المتحولة\nالكوليسترول\nالصوديوم\nمجمع الكربوهيدرات\nالألياف\nالسكريات\nالسكر المضاف\nالبروتين",
                        labelText6: "فيتامين د\nالكالسيوم\nالحديد\nالبوتاسيوم",
                        allergenIconsTitle: "أيقونات مسببات الحساسية",
                        allergenIconsDescription: "تمثل هذه الأيقونات مسببات الحساسية الشائعة، مما يساعدك على التعرف بسرعة على المكونات التي يجب تجنبها بسبب الحساسية.",
                        allergens: [
                            { id: 1, name: "الغولتين", icon: "assets/images/allergens/gluten.png" },
                            { id: 2, name: "الفول السوداني", icon: "assets/images/allergens/peanuts.png" },
                            { id: 3, name: "كرفس", icon: "assets/images/allergens/celery.png" },
                            { id: 4, name: "المكسرات", icon: "assets/images/allergens/nuts.png" },
                            { id: 5, name: "خردل", icon: "assets/images/allergens/mustard.png" },
                            { id: 6, name: "بيض", icon: "assets/images/allergens/eggs.png" },
                            { id: 7, name: "سمسم", icon: "assets/images/allergens/sesame.png" },
                            { id: 8, name: "حليب", icon: "assets/images/allergens/milk.png" },
                            { id: 9, name: "سمك", icon: "assets/images/allergens/fish.png" },
                            { id: 10, name: "فول الصويا", icon: "assets/images/allergens/soya.png" },
                        ],
                    },
                },
            };
        },


        created() {
            this.fetchMenuItems();
        },

        methods: {
            toggleLanguage() {
                this.currentLanguage = this.currentLanguage === "en" ? "ar" : "en";
                this.updateTexts();
            },
            updateTexts() {
                this.texts = this.translations[this.currentLanguage];
            },
            checkScreenSize() {
                this.isMobile = window.innerWidth <= 768; // تعديل الحدود حسب الحاجة
            },

            setActiveFilter(category) {
                this.activeFilter = category;
            },
            //---------------------------
            fetchMenuItems() {
                axios.get(`${this.publicUrl}/api/1fW9MC9DHYkjTkuAdQjFgZMLx5A99J7eP7OoJzyxVIQ8WoUktzbxHJjdzGr0/menu`)
                    .then(response => {
                        //console.log(response.data);
                        if (Array.isArray(response.data)) {
                            this.menuItems = [];
                            response.data.forEach(item => {
                                if (item.items && item.items.length > 0) {
                                    this.menuItems.push(item);
                                }
                            });
                        }
                        //this.menuItems = response.data;
                    })
                    .catch(error => {
                    console.error('Error fetching menu items:', error);
                });
            },

            
            getAllergenPoster(item) {
                switch (item) {
                    case 35:
                        return '/assets/images/allergens/gluten.png';
                    case 17:
                        return '/assets/images/allergens/peanuts.png';
                    case 22:
                        return '/assets/images/allergens/celery.png';
                    case 18:
                        return '/assets/images/allergens/nuts.png';
                    case 25:
                        return '/assets/images/allergens/mustard.png';
                    case 24:
                        return '/assets/images/allergens/eggs.png';
                    case 26:
                        return '/assets/images/allergens/sesame.png';
                    case 20:
                        return '/assets/images/allergens/milk.png';
                    case 16:
                        return '/assets/images/allergens/fish.png';
                    case 29:
                        return '/assets/images/allergens/soya.png';
                    default:
                        return ''; // صورة بديلة
                        //return '/assets/images/none.png'; // صورة بديلة
                }
            },

            getPoster(item) {
                if (item.poster) {
                    return `${this.publicUrl}/s/prepared_food/${item.poster}`;
                } else {
                    return '/assets/images/none.png'; // صورة بديلة
                }
            }
            //---------------------------


        },
        computed: {
            texts() {
                return this.translations[this.currentLanguage];
            },

            filteredItems() {
                let filtered = this.menuItems;
                
                // Filter by active category
                if (this.activeFilter !== '*') {
                    filtered = filtered.filter(item => item.category === this.activeFilter);
                }
                
                // Filter by search query
                if (this.searchQuery) {
                    const query = this.searchQuery.toLowerCase();
                    filtered = filtered.map(item => {
                        const filteredItems = item.items.filter(item_2 => {
                            const title = this.currentLanguage === 'ar' ? item_2.title_2 : item_2.title;
                            return title.toLowerCase().includes(query);
                        });
                        return { ...item, items: filteredItems };
                    }).filter(item => item.items.length > 0); // Remove categories with no matching items
                }
                
                return filtered;
            },
            /*filteredItems() {
                if (this.activeFilter === '*') {
                    return this.menuItems;
                } else {
                    return this.menuItems.filter(item => item.category === this.activeFilter);
                }
            },*/

        },
        mounted() {
            this.updateTexts(); // تحديث النصوص عند تحميل الصفحة
            setTimeout(() => {
                this.loading = false;
            }, 2000);
            this.checkScreenSize();
            window.addEventListener("resize", this.checkScreenSize);
        },
        beforeUnmount() {
            window.removeEventListener("resize", this.checkScreenSize);
        },

    };
</script>
